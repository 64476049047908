<template>
  <mobile-screen :header="true" :footer="true" screen-class="icon-app1 gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="outlook-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            resource && resource.data && resource.data.name
              ? resource.data.name
              : displayLabelName("plan", "resource", "resource")
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <template v-if="resource && resource.data">
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "resource", "name") }}
                <span>*</span></label
              >
              <p class="text-label">
                {{ resource.data.name }}
              </p>
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="resource.data.level_id && selectedResourceLevel"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "resource", "level") }}
                <span>*</span></label
              >
              <p class="text-label">
                {{ selectedResourceLevel.name }}
              </p>
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="resource.data.resource_type_id && selectedResourceType"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "resource", "resource-type") }}
                <span>*</span></label
              >
              <p class="text-label">
                {{ selectedResourceType.name }}
              </p>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="resource.data.capacity">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "resource", "capacity") }}
                <span>*</span></label
              >
              <p class="text-label">
                {{ resource.data.capacity }}
              </p>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item" v-if="resource.data.cost">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "resource", "price-per-hour") }}
                <span>*</span></label
              >
              <p class="text-label">
                {{ resource.data.cost }}
              </p>
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="resource.data.resource_model_id && selectedResourceModel"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{ displayLabelName("plan", "resource", "resource-model") }}
                <span>*</span></label
              >
              <p class="text-label">
                {{ selectedResourceModel.name }}
              </p>
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="selectedResourceAttributes && selectedResourceAttributes.length"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{
                  displayLabelName("plan", "resource", "resource-attribute")
                }}
                <span>*</span></label
              >
              <p class="text-label">
                {{ displayResourceAttributes() }}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item" v-if="resource.data.description">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{
                  displayLabelName("resources", "edit-resource", "description")
                }}
                <span>*</span></label
              >
              <p v-html="resource.data.description" class="text-label"></p>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="resourceImage" class="img-wrapper">
        <img :src="resourceImage" alt="Resource image" />
      </div>
      <div v-if="resource.data.qrcode" class="img-wrapper">
        <img :src="resource.data.qrcode" alt="QR code" />
      </div>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "PlanResourceDetail",
  created() {
    this.getResource({ id: this.$route.params.resource_id });
    this.setSelectedResourceLevel(null);
    this.getResourceAttributes();
    this.getResourceImage(this.$route.params.resource_id);
  },
  data() {
    return {
      backLinkName: "r_services-resources",
    };
  },
  computed: {
    ...mapState("resource", [
      "resource",
      "selectedResourceLevel",
      "selectedResourceType",
      "selectedResourceModel",
      "selectedResourceAttributes",
    ]),
    ...mapGetters("resource", ["resourceImage"]),
    routeParam() {
      const { resource_id } = this.$route.params;
      if (resource_id) return resource_id;
      return null;
    },
  },
  watch: {
    routeParam() {
      if (this.routeParam) {
        this.getResource({ id: this.routeParam });
        this.getResourceImage(this.routeParam);
      }
    },
  },
  methods: {
    ...mapActions("resource", [
      "setSelectedResourceLevel",
      "getResource",
      "getResourceAttributes",
      "getResourceImage",
    ]),
    displayResourceAttributes() {
      let display = "";

      this.selectedResourceAttributes.forEach((item, idx) => {
        display +=
          idx === this.selectedResourceAttributes.length - 1
            ? item.name
            : `${item.name}, `;
      });

      return (
        display ||
        this.displayLabelName("plan", "resource", "select-resource-attribute")
      );
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  beforeUnmount() {
    this.$store.commit("resource/setResourceImage", null, {
      root: true,
    });
  },
};
</script>
